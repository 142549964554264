import * as React from "react";
import { Link } from "gatsby";
import Layout from "../../components/Layout";
import { FocusAreaIcon } from "../../components/svg";
import Seo from "../../components/Seo";
import DownloadBox from "../../components/DownloadBox";
import LevercodePyramid from "../../images/levercode-pyramid.png";
import LevercodeStack from "../../images/levercode-stack.png";

//import { StaticImage } from "gatsby-plugin-image";

const GovernmentPage = () => (
  <Layout>
    <Seo title="e-Governance" />

    {/* Sub Header image */}
    <div className="_government_hero mt-32">
      <div className="flex">
        <FocusAreaIcon icon="government" className="mx-auto w-auto h-40 sm:h-80 -mt-20 text-yellow-800 fill-current" />
      </div>

      {/* Sub Header intro text */}
      <div className="container pt-8 pb-16 sm:pt-32 sm:pb-56 text-white">
        <h1 className="text-4xl sm:text-7xl leading-tight sm:leading-tight font-bold text-center">
          Fundamentals of{" "}
          <span className="whitespace-nowrap">
            <span className="text-yellow-800 text-5xl sm:text-8xl pr-1 sm:pr-2">[</span>Digital
          </span>{" "}
          <span className="whitespace-nowrap">
            Transformation<span className="text-yellow-800 text-5xl sm:text-8xl pl-1 sm:pl-2">]</span>
          </span>
        </h1>
        <p className="text-center text-xl leading-snug sm:text-3xl sm:leading-normal pt-8 sm:pt-16">Levercode’s Approach to e-Governance</p>
      </div>
    </div>
    {/* Content */}
    <div className="container max-w-screen-xl sm:text-2xl pt-8 pb-16 sm:pt-32 sm:pb-48">
      <p>
        Nearly all countries and Governments aspire to use digital technologies in providing services to its citizens and residents. However, way too often, after taking political, visionary decision, major focus lands on implementation of specific
        applications and solutions, <strong>leaving little attention on basic foundational data systems that are of vital importance for effective digital governance and integrity of the whole ecosystem</strong>.
      </p>

      <p className="_p">
        In Levercode’s view, it is impossible to complete <strong>Digital Transformation</strong> and achieve a reliable e-Governance ecosystem that is sustainable and operates on low data risk without establishing <strong>Digital Identity</strong>,{" "}
        <strong>Data Exchange Layer</strong> (known also as X-Road), and <strong>Registries</strong>. These three critical components, if set up in right way, create solid foundation for e-Governance system. This is exactly how Levercode can build value to
        your Government, country, or organization.
      </p>

      <p className="_p">
        <img src={LevercodePyramid} alt="Levercode Pyramid" />
      </p>

      <p className="_p">
        We believe that there is no „one size fits all “solution when it comes to your mission critical systems. Every country is different making the needs, objectives and preconditions different too.{" "}
        <strong>Levercode pays great attention to the Discovery Phase</strong> where, together with your participation and involvement, our experts learn about the wider landscape and identify the issues to be solved, as well as gaps to be filled. The
        outcome of this phase gives you full understanding of the starting point and detailed <strong>road map (Blueprint) for achieving a sustainable Digital Society</strong>.
      </p>

      <p className="_p">
        We design and model the e-Governance systems from the scratch. However, our Research Lab is capable of auditing and evaluating already existing systems' weaknesses, data and process risks, and provide recommendations for remedy. Naturally, we can
        also step in to fix or redesign the systems based on industry standards, clients’ needs, and preferences.
      </p>

      <p className="_p _bold">Our unique expertise and modular approach allow us to create systems that are scalable from 1.3 million people Estonia to societies with hundreds of million residents.</p>

      <p className="_p">
        Levercode is not only about creating foundation for e-Governance. We are also experts in turning trust-based solutions into proof-based platforms by{" "}
        <strong>implementing Proof Technologies, including Blockchain and other Distributed Ledger Technologies (DLT)</strong>. Application of such tools has proven itself in the handling and protection of sensitive data stored in medical, land,
        population, and many other critical records.
      </p>

      <p className="_p">
        In addition, Levercode excels in building data governance systems that <strong>provide compliance with the surrounding regulatory environment including GDPR and AML</strong>. We have deep knowledge in many important service verticals such as
        healthcare and fintech to name a few. “Levercode Stack” gives an overview of full ecosystem in which Levercode is capable of contributing meaningful value.
      </p>

      <p className="_p">
        <img src={LevercodeStack} alt="Levercode Stack" />
      </p>

      <p className="_p">
        Please{" "}
        <Link to="/contact" className="_link font-bold">
          contact us
        </Link>{" "}
        to learn more about Levercode’s approach to achieving Digital Transformation and the ways we can build value for your country or organization.
      </p>
      <DownloadBox text="Download PDF whitepaper – Digital Transformation & e-Governance" url="https://levercode.com/downloads/digital-transformation-and-e-governance-by-levercode.pdf" />
    </div>
  </Layout>
);

export default GovernmentPage;
